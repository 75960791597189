@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
    height: 100%;
}

@layer components {
    .link {
        @apply underline hover:text-brand-700;
    }

    .btn {
        @apply inline-block px-6 py-2.5 bg-brand-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-brand-700 hover:shadow-lg focus:bg-brand-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-brand-500 active:shadow-lg transition duration-150 ease-in-out;
    }

    .circle {
        @apply mx-2 h-4 w-4 rounded-full;
    }

    input {
        @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
    }

    textarea {
        @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
    }

    .checkbox {
        @apply inline-block w-auto rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 mt-0
    }
}

.loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    color: #6f1d23;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
}

@keyframes animloader {
    0% {
        box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
    }
    25% {
        box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
    }
    50% {
        box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
    }
    75% {
        box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
    }
    100% {
        box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
    }
}